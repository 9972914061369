<!--<div class='Global'></div>-->
<!--    全局月结设置-->

<template>
    <!--    广告投放管理-->
    <div class="Global" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button
                type="warning"
                v-show="readonly"
                @click="readonly = false"
                size="small"
                v-if="hasPrivilege('menu.report.global.edit')"
                >编辑</el-button
            >
            <el-button
                type="primary "
                v-show="!readonly"
                @click="handleSave"
                size="small"
                v-if="hasPrivilege('menu.report.global.edit')"
                >保存</el-button
            >
        </el-card>

        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                ref="form"
                :model="form"
                :rules="rules"
                label-position="center"
                label-width="80px"
                size="small"
            >
                <el-row>
                    <el-col :span="4">
                        <el-form-item label="一月" prop="january">
                            <el-input
                                type="number"
                                v-model.number="form.january"
                                :readonly="readonly"
                                min="1"
                                max="31"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="二月" prop="february">
                            <el-input
                                type="number"
                                v-model.number="form.february"
                                :readonly="readonly"
                                min="1"
                                max="28"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="三月" prop="march">
                            <el-input
                                type="number"
                                v-model.number="form.march"
                                :readonly="readonly"
                                min="1"
                                max="31"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="四月" prop="april">
                            <el-input
                                type="number"
                                v-model.number="form.april"
                                :readonly="readonly"
                                min="1"
                                max="30"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="五月" prop="may">
                            <el-input
                                type="number"
                                v-model.number="form.may"
                                :readonly="readonly"
                                min="1"
                                max="31"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="六月" prop="june">
                            <el-input
                                type="number"
                                v-model.number="form.june"
                                :readonly="readonly"
                                min="1"
                                max="30"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="七月" prop="july">
                            <el-input
                                type="number"
                                v-model.number="form.july"
                                :readonly="readonly"
                                min="1"
                                max="31"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="八月" prop="august">
                            <el-input
                                type="number"
                                v-model.number="form.august"
                                :readonly="readonly"
                                min="1"
                                max="31"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="九月" prop="september">
                            <el-input
                                type="number"
                                v-model.number="form.september"
                                :readonly="readonly"
                                min="1"
                                max="30"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="十月" prop="october">
                            <el-input
                                type="number"
                                v-model.number="form.october"
                                :readonly="readonly"
                                min="1"
                                max="31"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="十一月" prop="november">
                            <el-input
                                type="number"
                                v-model.number="form.november"
                                :readonly="readonly"
                                min="1"
                                max="30"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="十二月" prop="december">
                            <el-input
                                type="number"
                                v-model.number="form.december"
                                :readonly="readonly"
                                min="1"
                                max="31"
                                step="1"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'Global',
    data() {
        const singleRule = function (max) {
            return [
                { type: 'integer', message: '日期错误', trigger: 'blur' },
                { type: 'number', min: 1, message: '日期错误', trigger: 'blur' },
                { type: 'number', max: max, message: '日期错误', trigger: 'blur' },
                { required: true, message: '日期错误', trigger: 'blur' },
            ];
        };

        return {
            form: {
                january: 1,
                february: 1,
                march: 1,
                april: 1,
                may: 1,
                june: 1,
                july: 1,
                august: 1,
                september: 1,
                october: 1,
                november: 1,
                december: 1,
            },
            rules: {
                january: singleRule(31),
                february: singleRule(29),
                march: singleRule(31),
                april: singleRule(30),
                may: singleRule(31),
                june: singleRule(30),
                july: singleRule(31),
                august: singleRule(31),
                september: singleRule(30),
                october: singleRule(31),
                november: singleRule(30),
                december: singleRule(31),
            },
            readonly: true,
            url: '/report/monthSettle/setting/global',
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            _this.$http
                .get(_this.url)
                .then((rst) => {
                    if (rst.data.status == 200) {
                        _this.form.january = rst.data.data.january;
                        _this.form.february = rst.data.data.february;
                        _this.form.march = rst.data.data.march;
                        _this.form.april = rst.data.data.april;
                        _this.form.may = rst.data.data.may;
                        _this.form.june = rst.data.data.june;
                        _this.form.july = rst.data.data.july;
                        _this.form.august = rst.data.data.august;
                        _this.form.september = rst.data.data.september;
                        _this.form.october = rst.data.data.october;
                        _this.form.november = rst.data.data.november;
                        _this.form.december = rst.data.data.december;
                    } else {
                        _this.$message.error(rst.data.message);
                    }
                })
                .catch((rst) => {
                    _this.$message.error(rst.response.data.message);
                });
        },
        handleSave() {
            const _this = this;

            this.$refs.form.validate((valid) => {
                if (!valid) return;
                _this.readonly = true;
                _this.$http
                    .post(_this.url, _this.form)
                    .then((rst) => {
                        if (rst.data.status == 200) {
                            _this.$message.success('保存成功');
                        } else {
                            _this.$message.error(rst.data.message);
                        }
                    })
                    .catch((rst) => {
                        _this.$message.error(rst.response.data.message);
                    });
            });
        },
        handleExport() {},
    },
};
</script>

<style scoped>
.Global .el-form-item {
    margin-bottom: 0;
}

.Global label.el-form-item__label {
    padding-right: 0;
}
</style>
